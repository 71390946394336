import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '@blaze-react/input';
import Button from '@blaze-react/button';
import { Checkbox } from '@blaze-react/checkboxes';
import { useEcommerceContext } from '@blaze-cms/ecommerce-context';
import { withCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { EMAIL, PASSWORD, LOGIN_FORM, REGISTER_EMAIL_KEY, COOKIE_NAME } from '../../constants';

const LoginComponent = ({ cookies, onLogin, setGuestRegisterEmail, useGuestLoginLayout }) => {
  const router = useRouter();
  const { cartId, updateCartId } = useEcommerceContext();
  const [email, setEmail] = useState('');
  const [guestCheckoutSelect, setGuestCheckoutSelected] = useState(false);
  const [registerForANewAccount, setRegisterForANewAccount] = useState(false);
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(
    () => () => {
      // when login component unmounted set the context token as the cartId
      if (cookies.get('blaze_auth') && cookies.get(COOKIE_NAME)) {
        // check if logged in
        updateCartId(cookies.get(COOKIE_NAME));
      }
    },
    [cookies, updateCartId]
  );

  const handleChange = ({ event }) => {
    const {
      target: { name: iName, value: iValue }
    } = event;

    if (iName === EMAIL) setEmail(iValue);
    if (iName === PASSWORD) setPassword(iValue);
  };

  const handleContinue = () => {
    if (registerForANewAccount) {
      handleRegister();
    } else {
      setGuestRegisterEmail(email);
    }
  };

  const handleRegister = () => {
    localStorage.setItem(REGISTER_EMAIL_KEY, email);
    router.push('/Resolver', '/register');
  };

  const isDisabled = guestCheckoutSelect || registerForANewAccount ? !email : !email || !password;

  return (
    <form
      className="login-form"
      id={LOGIN_FORM}
      onSubmit={e => {
        e.preventDefault();
        if (isDisabled) return;

        onLogin({ authData: { email, password, swContextToken: cartId }, rememberMe });
      }}>
      <Input
        id="blaze-login-email"
        key="email"
        type="email"
        name="email"
        placeholder="example@email.com"
        label="Email"
        required
        onChange={handleChange}
        value={email}
      />
      {useGuestLoginLayout && (
        <>
          <p>
            <b>Do you have an account?</b>
            <br />
            If you sign in, it will be quicker to complete your order
          </p>

          <div className="form-field form-field--radio" role="button">
            <input
              name="isGuest"
              type="radio"
              className="form-radio"
              value="guest"
              checked={guestCheckoutSelect}
              onChange={() => {
                setGuestCheckoutSelected(true);
                setRegisterForANewAccount(false);
              }}
            />
            <label>Guest checkout</label>
          </div>
          <div className="form-field form-field--radio" role="button">
            <input
              name="isRegister"
              type="radio"
              className="form-radio"
              value="register"
              checked={registerForANewAccount}
              onChange={() => {
                setRegisterForANewAccount(true);
                setGuestCheckoutSelected(false);
              }}
            />
            <label>Register for a new account</label>
          </div>
          <div className="form-field form-field--radio" role="button">
            <input
              name="isPassword"
              type="radio"
              className="form-radio"
              value="password"
              checked={!guestCheckoutSelect && !registerForANewAccount}
              onChange={() => {
                setGuestCheckoutSelected(false);
                setRegisterForANewAccount(false);
              }}
            />
            <label>Yes, my password is:</label>
          </div>
        </>
      )}
      {!guestCheckoutSelect && !registerForANewAccount ? (
        <>
          <Input
            id="blaze-login-password"
            key="password"
            type="password"
            name="password"
            label="Password"
            required
            onChange={handleChange}
            value={password}
          />
          <Checkbox
            checked={rememberMe}
            label="Remember Me"
            name="rememberme"
            id="remember-me"
            data-testid="remember-me"
            onChange={({ value: { checked } }) => setRememberMe(checked)}
          />
          <Button type="submit" name="button" form={LOGIN_FORM} disabled={!email}>
            Login
          </Button>
        </>
      ) : (
        <Button type="submit" name="button" disabled={isDisabled} onClick={handleContinue}>
          Continue
        </Button>
      )}
    </form>
  );
};

LoginComponent.propTypes = {
  onLogin: PropTypes.func.isRequired,
  useGuestLoginLayout: PropTypes.bool.isRequired,
  setGuestRegisterEmail: PropTypes.func.isRequired,
  cookies: PropTypes.object.isRequired
};

export default withCookies(LoginComponent);
